<!--
 * @Author: Dyf
 * @LastEditors: Please set LastEditors
 * @Date: 2023-04-03 13:47:52
 * @LastEditTime: 2023-11-30 21:44:59
 * @Descripttion: 综合分析
-->
<style lang="scss" scoped>
.synthesis {
    @include innerPage;
    @include pageHead(-10px);

    .page-head {
        height: 240px;
        flex-wrap: wrap;
        padding-top: 0;

        .head-wrapper.column {
            margin-top: 12px;
        }

        .page-tab {
            &--wrapper {
                width: 100%;
                height: 76px;
                @include flexBox;

                .student {
                    height: 66px;
                    @include flexBox;
                    margin-right: 190px;
                    margin-left: 22px;

                    .name {
                        margin: 0 32px 0 16px;
                        font-size: 16px;
                        color: #343434;
                    }

                    .all-circle {
                        width: 66px;
                        height: 66px;
                        border-radius: 50%;
                        background: #6c4ecb;
                        color: #fff;
                        line-height: 66px;
                        text-align: center;
                        margin-right: 16px;
                    }
                }
            }

            width: auto;
            justify-content: flex-start;

            .tabs {
                border-bottom: none;
                justify-content: flex-start;
            }

            .more-subject {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background: #6c4ecb;
                margin-right: auto;
                margin-left: 60px;
                margin-top: 6px;
                cursor: pointer;

                &:hover {
                    background: #8971d5;
                }

                .iconfont {
                    font-size: 18px;
                    text-align: center;
                    line-height: 24px;
                    color: #fff;
                }
            }
        }
    }

    .page-inner {
        width: calc(100% + 60px);
        height: calc(100% - 270px);
        box-sizing: border-box;
        padding-right: 60px;
        margin-top: 30px;
        overflow: hidden;
        overflow-y: auto;
        flex-wrap: wrap;
        @include flexBox(space-between, flex-start);

        .plate-box {
            width: calc((100% - 32px) / 2);
            height: 390px;
            background: #fff;
            border-radius: 10px;

            &:nth-child(n + 3) {
                height: 520px;
                margin-top: 36px;
            }

            .center-text {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                flex-direction: column;
                color: #6c4ecb;
                @include flexBox(center);

                .percentage {
                    font-size: 20px;
                    line-height: 1em;

                    span {
                        font-size: 14px;
                    }
                }

                .tips {
                    font-size: 16px;
                    color: #444;
                    line-height: 24px;
                }

                &.success {
                    color: #2ac293;
                }

                &.warning {
                    color: #fcb332;
                }

                &.danger {
                    color: #f66478;
                }
            }
        }

        .ratio {
            box-sizing: border-box;
            padding-left: 14px;
            @include flexBox(center);

            &-gird {
                width: 61.3%;
                max-width: 494px;
                height: 372px;
                margin-right: 34px;
                position: relative;
                flex-wrap: wrap;
                @include flexBox;

                li {
                    width: 50%;
                    height: 50%;
                    flex-direction: column;
                    @include flexBox(center);

                    .rate-box {
                        width: 100px;
                        height: 100px;
                        position: relative;
                        margin: 0 auto;
                    }

                    .label {
                        color: #444;
                        font-size: 16px;
                        margin-top: 18px;
                    }
                }

                &::before,
                &::after {
                    content: '';
                    background: rgba($color: #898989, $alpha: .5);
                    position: absolute;
                }

                &::before {
                    width: 100%;
                    height: 1px;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%) scaleY(0.5);
                }

                &::after {
                    height: 100%;
                    width: 1px;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%) scaleX(0.5);
                }
            }

            .wq-entry {
                width: 30.27%;
                max-width: 244px;
                height: 372px;
                box-sizing: border-box;
                padding: 0 24px;
                border-left: 1px solid rgba($color: #898989, $alpha: .24);
                flex-direction: column;
                @include flexBox(center);

                li {
                    width: 100%;
                    height: 102px;
                    border-radius: 20px;
                    box-sizing: border-box;
                    padding-right: 19%;
                    background: #6340c8 url("~@/assets/images/bg-wq.png") left 10px no-repeat;
                    cursor: pointer;
                    flex-direction: column;
                    @include flexBox(center, flex-end);

                    &:last-child {
                        margin-top: 14px;
                        background: #fcb332 url("~@/assets/images/bg-wq2.png") left 10px no-repeat;
                    }

                    p {
                        line-height: 24px;
                        color: #fff;

                        &.pf_bold {
                            font-size: 24px;
                        }

                        &.bold {
                            font-size: 16px;
                            margin-top: 4px;
                        }
                    }
                }
            }
        }

        .practise,
        .homework-grade,
        .homework-trend {
            width: 100%;
            height: calc(100% - 72px);
        }

        .practise {
            @include flexBox;

            .accuracy {
                width: 52.1%;
                height: calc(100% - 4px);
                margin-top: 2px;
                box-sizing: border-box;
                border-right: 1px solid rgba($color: #898989, $alpha: .24);
                flex-direction: column;
                @include flexBox(center);

                .rate-box {
                    width: 138px;
                    height: 138px;
                    position: relative;
                    margin: 0 auto;
                }

                .velocity {
                    width: calc(100% - 50px);
                    height: 56px;
                    border-radius: 10px;
                    background: #f6f7fc;
                    box-sizing: border-box;
                    padding-left: 26px;
                    font-size: 16px;
                    color: #2ac293;
                    margin-top: 48px;
                    @include flexBox;

                    b {
                        color: #393939;
                        margin-right: 8px;
                    }

                    &.down {
                        color: #f66478;
                    }
                }
            }

            .questions-num {
                width: calc(47.9% - 48px);
                height: calc(100% - 4px);
                margin: 2px auto 0;
                position: relative;

                &::before {
                    content: '';
                    width: 100%;
                    border-bottom: 1px dashed rgba($color: #898989, $alpha: .24);
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                }

                li {
                    height: 50%;
                    font-size: 16px;
                    color: #2a2a2a;
                    flex-direction: column;
                    @include flexBox(center);

                    b {
                        font-size: 30px;
                        color: #2ac293;
                        margin-bottom: 16px;
                    }

                    &:last-child b {
                        color: #f66478;
                    }
                }
            }
        }

        .homework-grade {
            .grade {
                &--wrapper {
                    width: 100%;
                    height: calc(100% - 128px);
                    margin-top: 32px;
                    @include flexBox;
                }

                &--echarts {
                    width: 72%;
                    height: 100%;
                }

                &--statistics {
                    width: 28%;
                    height: 100%;
                    flex-direction: column;
                    @include flexBox(center);

                    li {
                        margin-bottom: 46px;
                        @include flexBox;

                        .icon {
                            width: 48px;
                            height: 48px;
                            background: rgba($color: #e6e4f3, $alpha: .39);
                            border-radius: 10px;
                            @include flexBox(center);
                        }

                        p {
                            color: #5c5c5c;
                            margin-left: 16px;
                            flex-direction: column;
                            @include flexBox(center, flex-start);

                            b {
                                font-size: 24px;
                                color: #2b2b2b;
                            }
                        }

                        &:nth-child(2) {
                            .icon {
                                background: rgba($color: #ffe6e8, $alpha: .39);
                            }
                        }

                        &:last-child {
                            margin-bottom: 0;

                            .icon {
                                box-sizing: border-box;
                                padding-top: 10px;
                                background: #fffbf1;
                            }
                        }
                    }
                }

            }

            .foot-info {
                width: calc(100% - 68px);
                height: 54px;
                margin: 22px auto 0;
                background: #f3f3f3;
                border-radius: 10px;
                box-sizing: border-box;
                padding: 0 34px 0 42px;
                @include flexBox;

                .level-grade {
                    width: 392px;
                    height: 54px;
                    margin-right: auto;
                    color: #282828;
                    @include flexBox(space-between);
                }

                .echarts-legend {
                    @include flexBox;

                    li {
                        box-sizing: border-box;
                        padding-left: 26px;
                        position: relative;
                        color: #3f3f3f;
                        margin-left: 20px;

                        &::before {
                            content: '';
                            width: 20px;
                            height: 10px;
                            border-radius: 3px;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }

                        &:nth-child(1)::before {
                            background: #6340c8;
                        }

                        &:nth-child(2)::before {
                            background: #feaf2f;
                        }
                    }
                }
            }
        }

        .homework-trend {
            .trend-chart {
                &--tabs {
                    width: calc(100% - 96px);
                    height: 68px;
                    position: relative;
                    margin-left: 60px;
                    border-bottom: 1px solid rgba($color: #626262, $alpha: 0.08);
                    @include flexBox;

                    &:after {
                        content: '';
                        width: 38px;
                        height: 5px;
                        border-radius: 2.5px;
                        background: #58419c;
                        position: absolute;
                        left: 12px;
                        bottom: 0;
                        @include defaultAni;
                    }

                    &.exam::after {
                        transform: translateX(160px);
                    }

                    li {
                        cursor: pointer;
                        color: #373639;

                        &:first-child {
                            padding-right: 52px;
                        }

                        &:last-child {
                            padding-left: 52px;
                            position: relative;

                            &::after {
                                content: '';
                                height: 20px;
                                border-left: 1px solid rgba($color: #7a7a7a, $alpha: 0.2);
                                position: absolute;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }

                        &.current,
                        &:not(.current):hover {
                            color: #6c4ecb;
                        }
                    }
                }

                &--echarts {
                    width: 100%;
                    height: calc(100% - 82px);
                }
            }
        }
    }
}
</style>
<style lang="scss">
.student-item {
    width: 200px;
    height: 74px;
    box-sizing: border-box;
    padding-left: 18px;
    font-size: 16px;
    color: #2b2b2b;
    @include flexBox;

    .el-avatar {
        flex-shrink: 0;
        margin-right: 10px;
    }
    span:not(.el-avatar){
        display: inline-flex;
        @include ellipsisMultiline(2)
    }

    .all {
        display: inline-block;
        width: 62px;
        height: 62px;
        border-radius: 50%;
        background: #6340c8;
        font-size: 14px;
        color: #fff;
        text-align: center;
        line-height: 62px;
    }
}

.el-dropdown-menu.dropdown-menu-student {
    background: #f7f7ff;

    .el-dropdown-menu__item {
        padding: 0;
        border: 1px solid transparent;

        .all {
            background: rgba($color: #6340c8, $alpha: 0.06);
            border: 1px solid #c0adf9;
            color: #6340c8;
        }

        &:not(.current):hover {
            background: transparent;
            border-color: #6c4ecb;
            border-radius: 10px;
        }

        &.dropdown--all.current {
            background: transparent;
            border-color: transparent;

            .all {
                background: #6340c8;
                border-color: #6340c8;
                color: #fff;
            }
        }

        &.current span {
            color: #fff;
        }

        &:first-child:hover {
            background: transparent;
            border-color: transparent;

            .all {
                background: #6340c8;
                border-color: #6340c8;
                color: #fff;
            }
        }
    }
}
</style>

<template>
    <section class="synthesis">
        <div class="page-head">
            <div class="page-head--inner">
                <img class="bitmap" src="@assets/images/bitmap-head.png" alt="">
                <div class="head-wrapper">
                    <breadcrumb />
                </div>
            </div>
            <div class="page-tab--wrapper">
                <!-- 学生 -->
                <div class="student">
                    <template v-if="!$isEmpty(currentStudent)">
                        <el-avatar :size="66" :src="formatfile(currentStudent.stuser_image)">
                            <img src="@assets/images/empty_avatar.png" />
                        </el-avatar>
                        <span class="name">{{ currentStudent.stuser_name }}</span>
                    </template>
                    <p class="all-circle" v-else>全部</p>
                    <el-dropdown class="more-student" placement="bottom-start" trigger="click"
                        v-if="teachStudents.length > 0">
                        <el-button type="custom_primary" size="medium">
                            切换<i class="iconfont">&#xe649;</i>
                        </el-button>
                        <el-dropdown-menu class="dropdown-menu-student" slot="dropdown">
                            <el-dropdown-item class="dropdown--all" :class="{ current: $isEmpty(currentStudent) }"
                                @click.native="chooseStudent(null)">
                                <p class="student-item">
                                    <span class="all">全部</span>
                                </p>
                            </el-dropdown-item>
                            <el-dropdown-item v-for="item in teachStudents" :key="item.stuser_id"
                                :class="{ current: !$isEmpty(currentStudent) && item.stuser_id == currentStudent.stuser_id }"
                                @click.native="chooseStudent(item)">
                                <p class="student-item">
                                    <el-avatar :size="62" :src="formatfile(item.stuser_image)">
                                        <img src="@assets/images/empty_avatar.png" />
                                    </el-avatar>
                                    <span>{{ item.stuser_name }}</span>
                                </p>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <!-- 科目 -->
                <div class="page-tab">
                    <ul class="tabs bold">
                        <li class="tab" :class="{ current: !searchForm.sysub_id }" @click="switchSubject(null)">全部</li>
                        <li class="tab" :class="{ current: searchForm.sysub_id == item.sysub_id }"
                            v-for="(item, index) in teachSubject.slice(0, 4)" :key="item.sysub_id"
                            @click="switchSubject(index)">
                            {{ item.sysub_name }}
                        </li>
                    </ul>
                    <el-dropdown class="more-subject" placement="bottom-start" v-if="teachSubject.length > 4">
                        <p class="iconfont">&#xe8aa;</p>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="(item, index) in teachSubject.slice(4, teachSubject.length)"
                                :key="item.sysub_id" @click.native="switchSubject(index + 4)">
                                {{ item.sysub_name }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
        <div class="page-inner">
            <!-- 各项数据比率 -->
            <div class="plate-box ratio">
                <template v-if="analysisData.finish_data">
                    <ul class="ratio-gird">
                        <li>
                            <div class="rate-box">
                                <el-progress class="percentage-progress success" :width="100" :stroke-width="10"
                                    type="circle" stroke-linecap="butt"
                                    :percentage="analysisData.finish_data.homework_rate || 0" :show-text="false" />
                                <div class="center-text success">
                                    <p class="pf_bold percentage">
                                        {{ analysisData.finish_data.homework_rate || 0 }}<span class="pf">%</span>
                                    </p>
                                </div>
                            </div>
                            <p class="label">作业完成率</p>
                        </li>
                        <li>
                            <div class="rate-box">
                                <el-progress class="percentage-progress" :width="100" :stroke-width="10" type="circle"
                                    stroke-linecap="butt" :percentage="analysisData.finish_data.practice_rate || 0"
                                    :show-text="false" />
                                <div class="center-text">
                                    <p class="pf_bold percentage">
                                        {{ analysisData.finish_data.practice_rate || 0 }}<span class="pf">%</span>
                                    </p>
                                </div>
                            </div>
                            <p class="label">练习完成率</p>
                        </li>
                        <li>
                            <div class="rate-box">
                                <el-progress class="percentage-progress danger" :width="100" :stroke-width="10"
                                    type="circle" stroke-linecap="butt"
                                    :percentage="analysisData.finish_data.examination_rate || 0" :show-text="false" />
                                <div class="center-text danger">
                                    <p class="pf_bold percentage">
                                        {{ analysisData.finish_data.examination_rate || 0 }}<span class="pf">%</span>
                                    </p>
                                </div>
                            </div>
                            <p class="label">考试合格率</p>
                        </li>
                        <li>
                            <div class="rate-box">
                                <el-progress class="percentage-progress warning" :width="100" :stroke-width="10"
                                    type="circle" stroke-linecap="butt"
                                    :percentage="analysisData.finish_data.wrongquestion_rate || 0" :show-text="false" />
                                <div class="center-text warning">
                                    <p class="pf_bold percentage">
                                        {{ analysisData.finish_data.wrongquestion_rate || 0 }}<span class="pf">%</span>
                                    </p>
                                </div>
                            </div>
                            <p class="label">错题重做率</p>
                        </li>
                    </ul>
                    <ul class="wq-entry">
                        <li @click="tapWrongQues('ANALYSIS_WRONGQUESTIONS')">
                            <p class="pf_bold">{{ analysisData.finish_data.wrongquestion_count || 0 }}</p>
                            <p class="bold">错题总数</p>
                        </li>
                        <li @click="tapWrongQues('ANALYSIS_DONEWRONGQUESTIONS')">
                            <p class="pf_bold">{{ analysisData.finish_data.wrongquestion_sure || 0 }}</p>
                            <p class="bold">已重做错题数</p>
                        </li>
                    </ul>
                </template>
            </div>
            <!-- 课堂练习情况 -->
            <div class="plate-box">
                <h4 class="box-head">
                    <span class="bold">课堂练习情况</span>
                </h4>
                <div class="practise" v-if="analysisData.classroom_data">
                    <div class="accuracy">
                        <div class="rate-box">
                            <el-progress class="percentage-progress danger" :width="138" :stroke-width="10" type="circle"
                                stroke-linecap="butt" :percentage="analysisData.classroom_data.sure_rate || 0"
                                :show-text="false" />
                            <div class="center-text danger">
                                <p class="pf_bold percentage">
                                    {{ analysisData.classroom_data.sure_rate || 0 }}<span class="pf">%</span>
                                </p>
                                <p class="tips">正确率</p>
                            </div>
                        </div>
                        <p class="velocity" :class="{ down: analysisData.classroom_data.yoy < 0 }"
                            v-if="analysisData.classroom_data.yoy">
                            <b>答题平均速度：</b>
                            <span>{{ formatSeconds(analysisData.classroom_data.answer_speed) }}&nbsp;&nbsp;</span>
                            <span>同比{{ analysisData.classroom_data.yoy > 0 && '增长' || '降低'
                            }}{{ Math.abs(analysisData.classroom_data.yoy) }}%</span>
                            <i class="iconfont" v-if="analysisData.classroom_data.yoy > 0">&#xe643;</i>
                            <i class="iconfont" v-if="analysisData.classroom_data.yoy < 0">&#xe71d;</i>
                        </p>
                    </div>
                    <ul class="questions-num">
                        <li>
                            <b class="pf_bold">{{ analysisData.classroom_data.finish_count || 0 }}</b>
                            <span>已完成题目数量</span>
                        </li>
                        <li>
                            <b class="pf_bold">{{ analysisData.classroom_data.nofinish_count || 0 }}</b>
                            <span>未完成题目数量</span>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 作业评分占比 -->
            <div class="plate-box">
                <h4 class="box-head">
                    <span class="bold">作业评分占比</span>
                </h4>
                <div class="homework-grade">
                    <template v-if="analysisData.score_data">
                        <div class="grade--wrapper">
                            <div class="grade--echarts" ref="grade"></div>
                            <ul class="grade--statistics">
                                <li>
                                    <i class="icon"><img src="@assets/images/homework-icon.png" alt=""></i>
                                    <p>
                                        <b class="pf_bold">{{ analysisData.score_data.homework_count || 0 }}</b>
                                        <span>应交作业</span>
                                    </p>
                                </li>
                                <li>
                                    <i class="icon"><img src="@assets/images/homework-icon2.png" alt=""></i>
                                    <p>
                                        <b class="pf_bold">{{ analysisData.score_data.surehomework_count || 0 }}</b>
                                        <span>实交作业</span>
                                    </p>
                                </li>
                                <li>
                                    <i class="icon"><img src="@assets/images/homework-icon3.png" alt=""></i>
                                    <p>
                                        <b class="pf_bold">{{ analysisData.score_data.excellent_count || 0 }}</b>
                                        <span>评优作业</span>
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div class="foot-info">
                            <p class="level-grade">
                                <span v-for="(item, index) in analysisData.score_data.abcd_rate" :key="index">{{ item.code
                                }}:
                                    {{ item.rate || 0 }}%
                                </span>
                            </p>
                            <ul class="echarts-legend">
                                <li>班级平均</li>
                                <li>个人</li>
                            </ul>
                        </div>
                    </template>

                </div>
            </div>
            <!-- 作业成绩趋势 -->
            <div class="plate-box">
                <h4 class="box-head">
                    <span class="bold">作业成绩趋势</span>
                </h4>
                <div class="homework-trend">
                    <ul class="trend-chart--tabs" :class="{ exam: searchForm.avg_type == 1 }">
                        <li :class="{ current: searchForm.avg_type == 2 }" @click="changeTrend(2)">作业成绩</li>
                        <li :class="{ current: searchForm.avg_type == 1 }" @click="changeTrend(1)">考试成绩</li>
                    </ul>
                    <div class="trend-chart--echarts" ref="trend">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { $analysisData, $students } from "@api/analysis"
import { $getTeachSubject } from "@api/teaching"
import { formatFile } from "@utils"
export default {
    name: 'analysis_synthesis',
    computed: {
        formatfile() {
            return function (url) {
                return formatFile(url)
            }
        }
    },
    data() {
        return {
            searchForm: {}, // 统计数据筛选条件
            teachSubject: [], // 教师所授班级科目
            teachStudents: [], // 教师所授班级学生
            currentStudent: null,
            analysisData: {}, // 统计数据
            gradeInit: true, // 初始化作业评分占比图表
            trendChange: true, // 绘制作业成绩趋势图表
        }
    },
    created() {
        let { id } = this.$route.params;
        this.searchForm = {
            ...this.searchForm,
            sccla_id: id.split(',')[1],
            avg_type: 2
        }
        this.getSubject();
        this.getStudents();
        this.getData();
    },
    methods: {
        /** 时分秒格式转换 */
        formatSeconds(value) {
            var secondTime = parseInt(value);// 秒
            var minuteTime = 0;// 分
            var hourTime = 0;// 小时
            if (secondTime > 60) {//如果秒数大于60，将秒数转换成整数
                //获取分钟，除以60取整数，得到整数分钟
                minuteTime = parseInt(secondTime / 60);
                //获取秒数，秒数取余，得到整数秒数
                secondTime = parseInt(secondTime % 60);
                //如果分钟大于60，将分钟转换成小时
                if (minuteTime > 60) {
                    //获取小时，获取分钟除以60，得到整数小时
                    hourTime = parseInt(minuteTime / 60);
                    //获取小时后取余的分，获取分钟除以60取余的分
                    minuteTime = parseInt(minuteTime % 60);
                }
            }
            var result = "" + parseInt(secondTime) + "秒";

            if (minuteTime > 0) {
                result = "" + parseInt(minuteTime) + "分" + result;
            }
            if (hourTime > 0) {
                result = "" + parseInt(hourTime) + "小时" + result;
            }
            return result;
        },
        /** 获取教师所授班级科目 */
        async getSubject() {
            let { data } = await $getTeachSubject(this.searchForm.sccla_id);
            this.teachSubject = data;
        },
        /** 获取班级学生 */
        async getStudents() {
            let { data } = await $students(this.searchForm.sccla_id);
            this.teachStudents = data;
        },
        /** 获取统计数据 */
        async getData() {
            let { data } = await $analysisData(this.searchForm);
            this.analysisData = data;
            this.$nextTick(() => {
                if (!this.$isEmpty(data) && this.gradeInit) this.gradeEcharts();
                if (!this.$isEmpty(data) && this.trendChange) this.trendEcharts();
                this.trendChange = this.gradeInit = false;
                this.$forceUpdate();
            })
        },
        /** 切换学生 */
        chooseStudent(data) {
            if (data) {
                this.searchForm.stuser_id = data.stuser_id
            } else {
                delete this.searchForm.stuser_id
            }
            this.currentStudent = data;
            this.trendChange = this.gradeInit = true;
            this.getData();
        },
        /** 切换科目 */
        switchSubject(index) {
            if (index || index == 0) {
                this.searchForm.sysub_id = this.teachSubject[index].sysub_id;
                if (index > 3) {
                    let firData = this.teachSubject[index];
                    let replaceData = this.teachSubject[3];
                    this.teachSubject[3] = { ...firData };
                    this.teachSubject[index] = { ...replaceData };
                }
            } else {
                delete this.searchForm.sysub_id;
            }
            this.trendChange = this.gradeInit = true;
            this.getData();
        },
        /** 作业评分占比图表 */
        gradeEcharts() {
            let echarts = this.$echarts.init(this.$refs.grade);
            let { score_data } = this.analysisData;
            let seriesData1 = score_data.chart.map(item => item.class_rate);
            let seriesData2 = score_data.chart.map(item => item.stu_rate);
            echarts.resize();
            echarts.clear();
            let option = {
                tooltip: {
                    trigger: 'axis',
                    extraCssText: 'border-radius: 8px',
                    padding: [5, 10],
                    formatter: function (a) {
                        let list = []
                        let listItem = ''
                        for (var i = 0; i < a.length; i++) {
                            list.push(
                                `<p style="padding: 5px 0;" >
                                    <i style="display: inline-block;width: 8px;height: 8px;background:${a[i].color};border-radius: 2px;margin-right: 6px"></i>
                                    <span style="display: inline-block">${a[i].data}%</span>
                                </p>`
                            )
                        }
                        listItem = list.join('')
                        return `<div style="padding:6px;">${listItem}</div>`
                    },
                    textStyle: {
                        color: '#535353',
                        height: '24px',
                        fontSize: 14,
                        fontFamily: 'PingFang SC'
                    },
                    axisPointer: {
                        lineStyle: {
                            color: 'rgba(152, 126, 232, 0.26)'
                        }
                    },
                },
                color: ['#4a29a7', '#fcb332'],
                xAxis: [
                    {
                        type: 'value',
                        boundaryGap: false,
                        data: [0, 20, 40, 60, 80, 100],
                        min: 0,
                        max: 100,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#e9e9f4',
                                type: 'dashed',
                                dashOffset: 4,
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#e9e9f4',
                                type: 'dashed'
                            }
                        },
                        axisLabel: {
                            color: '#282828',
                            fontSize: 14,
                            fontFamily: 'MicrosoftYaHei',
                            interval: 20,
                            margin: 18,
                            formatter: function (value) {
                                return `${value}%`
                            },
                        }
                    }, {
                        type: 'value',
                        boundaryGap: false,
                        data: [0, 20, 40, 60, 80, 100],
                        min: 0,
                        max: 100,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#e9e9f4',
                                type: 'dashed',
                                dashOffset: 4,
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#e9e9f4',
                                type: 'dashed'
                            }
                        },
                        axisLabel: {
                            show: false
                        }
                    }
                ],
                yAxis: {
                    type: 'category',
                    scale: true,
                    data: ['D', 'C', 'B', 'A'],
                    interval: 10,
                    axisLine: {
                        lineStyle: {
                            color: '#e9e9f4',
                            type: 'dashed',
                            dashOffset: 4,
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            color: '#e9e9f4',
                            type: 'dashed'
                        }
                    },
                    axisLabel: {
                        color: '#282828',
                        fontSize: 16,
                        margin: 42,
                        fontFamily: 'PingFang SC'
                    },
                },
                grid: {
                    show: false,
                    top: "6px",
                    left: "90px",
                    right: "20px",
                    bottom: "30px"
                },
                series: [
                    {
                        type: 'bar',
                        data: seriesData1,
                        barWidth: 10,
                        itemStyle: {
                            normal: {
                                barBorderRadius: [0, 5, 5, 0]
                            }
                        }
                    },
                    {
                        type: 'bar',
                        data: seriesData2,
                        barWidth: 10,
                        itemStyle: {
                            normal: {
                                barBorderRadius: [0, 5, 5, 0]
                            }
                        }
                    }
                ]
            };
            echarts.setOption(option);
        },
        /** 作业成绩趋势图表 */
        trendEcharts() {
            let echarts = this.$echarts.init(this.$refs.trend);
            let { chart_data } = this.analysisData;
            let option = {
                tooltip: {
                    trigger: 'axis',
                    extraCssText: 'border-radius: 8px',
                    padding: [5, 10],
                    formatter: function (a) {
                        let list = []
                        let listItem = ''
                        for (var i = 0; i < a.length; i++) {
                            list.push(
                                `<p style="padding: 5px 0;" >
                                    <i style="display: inline-block;width: 8px;height: 8px;background:${a[i].color};border-radius: 2px;margin-right: 6px"></i>
                                    <span style="display: inline-block">${a[i].data}</span>
                                </p>`
                            )
                        }
                        listItem = list.join('')
                        return `<div style="padding:6px;">${listItem}</div>`
                    },
                    textStyle: {
                        color: '#535353',
                        height: '24px',
                        fontSize: 14,
                        fontFamily: 'PingFang SC'
                    },
                    axisPointer: {
                        lineStyle: {
                            color: 'rgba(152, 126, 232, 0.26)'
                        }
                    },
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: chart_data.x_data,
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#282828',
                        fontSize: 14,
                        fontFamily: 'MicrosoftYaHei',
                        margin: 18,
                    }
                },
                yAxis: [{
                    type: 'value',
                    scale: true,
                    interval: 10,
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#e9e9f4',
                            type: 'dashed',
                            dashOffset: 4,
                        }
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#e9e9f4',
                            type: 'dashed'
                        }
                    },
                    axisLabel: {
                        color: '#282828',
                        fontSize: 16,
                        fontFamily: 'PingFang SC',
                        margin: 20
                    }
                }, {
                    type: 'category',
                    scale: true,
                    interval: 10,
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#e9e9f4',
                            type: 'dashed',
                            dashOffset: 4,
                        }
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#e9e9f4',
                            type: 'dashed'
                        }
                    },
                    axisLabel: {
                        show: false
                    }
                },],
                grid: {
                    show: false,
                    top: "30px",
                    left: "70px",
                    right: "50px",
                    bottom: "50px"
                },
                series: [
                    {
                        type: 'line',
                        data: chart_data.y_data,
                        symbol: 'circle',
                        showSymbol: false,
                        symbolSize: 10,
                        smooth: true,
                        itemStyle: {
                            color: this.searchForm.avg_type == 1 && '#feaf2f' || '#987ee8',
                            borderWidth: 2,
                            borderColor: '#fff',
                            shadowColor: this.searchForm.avg_type == 1 && 'rgba(254, 175, 47, 0.53)' || 'rgba(99, 64, 200, 0.53)',
                            shadowBlur: 20
                        },
                        areaStyle: {
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: `rgba(${this.searchForm.avg_type == 1 && '254, 175, 47' || '152, 126, 232'}, 0.8)`
                                },
                                {
                                    offset: 0.5,
                                    color: `rgba(${this.searchForm.avg_type == 1 && '254, 175, 47' || '152, 126, 232'}, 0)`
                                }
                            ])
                        },
                    },
                ],
            };
            echarts.setOption(option);
        },
        /**
        * 切换趋势图表类型
        * @param {number} val 趋势图表类型 
        */
        changeTrend(val) {
            this.searchForm.avg_type = val;
            this.trendChange = true;
            this.getData();
        },
        /** 跳转错题列表 */
        tapWrongQues(name) {
            let params = {
                class: this.$route.params.class,
                id: `${this.searchForm.sccla_id},${this.currentStudent && this.currentStudent.stuser_id || 0},${this.searchForm.sysub_id || 0}`
            }
            this.$router.push({ name, params })
        }
    },
}
</script>